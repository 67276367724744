import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { LoggedInUserService } from '../services/loggedInUser/logged-in-user.service';
import { ShowToastrService } from '../services/show-toastr/show-toastr.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(
    private loggedInUserService: LoggedInUserService,
    private utilsService: UtilsService,
    private showToast: ShowToastrService,
    private router: Router,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        let msg = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          msg = error.message || error.error.message;
          errorMessage = `Error del lado del cliente: ${msg}`;
          this.showToast.showError(errorMessage, 'Error');
        } else if (error.error instanceof ProgressEvent) {
          msg = error.message;
          errorMessage = `Revise su conexión a internet: ${msg}`;
          this.showToast.showError(errorMessage, 'Error');
        } else {
          errorMessage = error.error;
          this.processingBackendError(error);
        }
        return throwError(() => error);
      }),
    );
  }

  // /////////////////////Procesing Error////////////////////////////////
  processingBackendError(err) {
    this.utilsService.errorHandle(err);
    if (err.status === 401) {
      localStorage.clear();
      this.router.navigate(['auth/login']).then();
      return;
    }
    if (err.status === 403) {
      this.router.navigate(['backend/error/acceso-prohibido']).then();
      return;
    }
    if (err.status === 404) {
      this.showToast.showInfo('El recurso solicitado no existe', 'Error 404!', 7000);
      return;
    }
    if (err.status === 426) {
      this.loggedInUserService.$showCountDown.next(err.error.value ? err.error.value : 10);
      return;
    }

    if (err.status === 400) {
      if (err.error.value === 25) {
        this.router.navigate(['backend/error/version-desactualizada']).then();
        return;
      }
    }
    // ///////////////////////////ERRORES DECONEXION PERDIDA o NO RESPUESTA DEL API////////////////////
    if (err.status === 0) {
      this.router.navigate(['backend/error/conexion-perdida']).then(r => false);
      return;
    }
    if (!err.status && !err.error) {
      this.router.navigate(['backend/error/conexion-perdida']).then(r => false);
      return;
    }
    // ////////////////////////////////////////////////////////////////////////////////////////////////
    return;
  }

  // /////////////////////////////////////////////////////
}
